/* General styles */


/* Hero Section */
.hero-section {
  position: relative;
  background-image: url("../../../public/assets/pic-2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  color: white;
  height: 100vh;
  width: 100%;
  margin-bottom: 5rem;
 
}



.hero-text {
  position: absolute;
  top: 80%;
  left: 50%;
  transform: translate(-60%, -20%);
  /* background-color: rgba(0, 0, 0, 0.5); */
  padding: 20px;
  border-radius: 10px;
  width: 80%;
}

.blue-box {
  position: absolute;
  background-color: #00529b;
  color: white;
  padding: 20px;
  width: 60%;
  margin: 20px;
  top: 90%;
  border-radius: 10px;
  /* margin-bottom: 40rem; */
}

/* Core Values Section */
.core-values {
  padding: 50px;
  background-color: #f9f9f9;

}

.core-values h2 {
  text-align: center;
  margin-bottom: 30px;
  margin-top: 60px;
}

.values {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.value {
  flex: 1 1 calc(30% - 20px);
  margin: 10px;
  padding: 15px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

/* Partners Section */
.partners-section {
  padding: 50px;
  background-color: #f9f9f9;
  text-align: center;
}

.partners-carousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  gap: 20px;
}

.partners-carousel img {
  max-width: 150px;
  height: auto;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .hero-section {
    position: relative;
    background-image: url("../../../public/assets/pic-2.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    color: white;
    height: 100vh;
    width: 100%;
    margin-bottom: 5rem;
   
  }
  /* .hero-text {
    position: absolute;
    margin-top: 80%;
    font-size: 16px;
    padding: 10px;
    width: 90%;
    padding-left: 50%;
  }
  .hero-text h1 {
    font-size: 20px;
    padding-left: 25px;
  }
  .hero-section{
    margin-bottom: 30%;
    height: 60%;
  } */
  .hero-text {
    position: absolute;
    margin-top: 80%;
    font-size: 16px;
    padding: 10px;
    width: 90%;
    padding-left: 50%;
  }
  .hero-text h1 {
    font-size: 24px;
    padding-left: 25px;
    margin-left: 20px;
    height: 60%;
    position: absolute;
    top: 100%;

  }

  .blue-box {
    position: absolute;
    top: 100%;
    font-size: 14px;
    margin: 0 auto;
    padding: 15px;
    width: 70%;
    margin-right: 50%;
    
  }

  .core-values {
    padding: 30px;
    margin-top: 180px;
  }

  .values {
    flex-direction: column;
    align-items: center;
  }

  .value {
    flex: 1 1 90%;
    margin: 10px 0;
  }

  .partners-section {
    padding: 30px;
  }

  .partners-carousel {
    gap: 10px;
  }
}

@media (max-width: 480px) {
  .hero-text {
    top: 5%;
    font-size: 14px;
    padding: 10px;
  }

  .blue-box {
    font-size: 12px;
    margin: 5px;
    padding: 10px;
  }

  .core-values {
    padding: 20px;
  }

  .values {
    flex-direction: column;
    align-items: stretch;
  }

  .value {
    flex: 1 1 100%;
    margin: 5px 0;
  }

  .partners-section {
    padding: 20px;
  }

  .partners-carousel img {
    max-width: 100px;
  }
}