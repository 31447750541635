.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.95);
    z-index: 1000;
  }
  
  
  .header-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .header.scrolled {
    background: #fff;
    color: #000;
  }

  .header.scrolled .nav-link{
    color: #000;
  }
  
  .logo {
    font-size: 1.5rem;
    font-weight: bold;
    text-decoration: none;
    color: #000;
  }
  
  .nav-menu {
    display: flex;
    gap: 2rem;
    align-items: center;
  }
  
  .nav-link {
    text-decoration: none;
    color: #000;
    font-size: 0.875rem;
    font-weight: 500;
    position: relative;
    padding-bottom: 5px;
  }
  
  .nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #333;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .nav-link:hover::after {
    transform: scaleX(1);
  }
  
  .language-selector {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }

  .language-selector img{
    width: 20px;
    height: 20px;
  }
  
  .language-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    padding: 0.5rem 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: none;
  }
  
  .language-dropdown.active {
    display: block;
  }
  
  .language-option {
    padding: 0.5rem 1rem;
    cursor: pointer;
  }
  
  .language-option:hover {
    background: #f5f5f5;
  }
  
  .hamburger {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .mobile-menu {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    color:#000;
    display: none;
    padding: 2rem;
  }

  .mobile-menu .mobile-nav-link{
    color:#000;
  }

  .close-menu{
    color:#000;
  }
  
  .mobile-menu.active {
    display: block;
  }
  
  .mobile-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }
  
  .close-menu {
    background: none;
    border: none;
    color: #000;
    font-size: 1.5rem;
    cursor: pointer;
  }
  
  .mobile-nav-link {
    display: block;
    color: white;
    text-decoration: none;
    padding: 1rem 0;
    font-size: 1rem;
    position: relative;
  }
  
  .mobile-nav-link::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: white;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .mobile-nav-link:hover::after {
    transform: scaleX(1);
  }
  
  .search-container {
    margin-top: 2rem;
    position: relative;
  }
  
  .search-input {
    width: 100%;
    padding: 0.5rem;
    background: transparent;
    border: none;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    color: white;
  }
  
  .search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
  }
  .mobile-nav-link img{
    width: 20px;
    height: 20px;
  }
  
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
    }
    
    .hamburger {
      display: block;
    }

  }
  
  