/* General Styles */


.container, .container-1 {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 20px;
}

.btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.btn-primary {
  background-color: #4fd1c5;
  color: white;
}

.btn-secondary {
  background-color: transparent;
  border: 1px solid #4fd1c5;
  color: #4fd1c5;
}

.btn:hover {
  opacity: 0.9;
}

/* Hero Section */
.home-section {
  min-height: 580px;
  display: flex;
  align-items: flex-end;
  padding: 48px;
  background-size: cover;
  background-position: center;

  position: relative;
  transition: background-image 1s ease-in-out;

}

.hero-section::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
}

.hero-section .container {
  position: relative;
  z-index: 1;
}

.hero-title {
  font-size: 20px;
  color: white;
  text-transform: uppercase;
  border: 1px solid white;
  display: inline-block;
  padding: 10px 20px;
  margin-bottom: 20px;
}

.hero-subtitle-container {
  color: white;
  max-width: 600px;
}

.hero-subtitle-container h2 {
  font-size: 36px;
  margin: 0;
}

/* Global Maritime Section */
.global-maritime {
  background-color: #001324;
  padding: 80px 0;
  color: white;
}

.global-maritime .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.global-maritime .content {
  flex: 1;
  padding-right: 40px;
}

.global-maritime h2 {
  color: #4fd1c5;
  margin-bottom: 20px;
}

.global-maritime .buttons {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.global-maritime .btn:hover{
  background-color: #4fd1c5;
  color: white;
}

.map-container {
  position: relative;
  flex: 1;
}

.map-glow {
  position: absolute;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(79,209,197,0.2) 0%, rgba(0,19,36,0) 70%);
  animation: glow 2s infinite alternate;
}

@keyframes glow {
  from { opacity: 0.5; }
  to { opacity: 1; }
}

.world-map {
  position: relative;
  z-index: 1;
  max-width: 100%;
}

/* Sustainability Section */
.sustainability {
  background-color: #065f46;
  padding: 80px 0;
  color: white;
}

.sustainability .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sustainability .image-container {
  flex: 1;
  padding-right: 25px;
}

.sustainability .image-container img {
  /* max-width: 100%; */
  width: 100%;
  border-radius: 10px;
}

.sustainability .content {
  flex: 1;
}

.sustainability .icons {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}

.sustainability .icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.sustainability .icon img {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}
.sustainability .icon span{
  color:#fff;
  text-align: center;
}

/* Latest News Section */
.container-2{
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}
.latest-news {
  background-color: #f3f4f6;
  padding: 10px 0;
}
.about-1-wrapper{
  display: grid;
  grid-template-columns: 2fr 2fr;
  gap: 40px;
  margin-top: 40px;
}



.news-content h3 {
  margin-bottom: 20px;
  color: #333;
}

.about-1 p {
  color: #333;
  margin-bottom: 20px;
  line-height: 1.5;
  word-spacing: 2px;
}

.news-video {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  height: 0;
  overflow: hidden;
}

.news-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* .container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */
 /* facts section */
h1 {
  font-size: 24px;
  margin-bottom: 20px;
}
table {
  width: 100%;
  border-collapse: collapse;
}
th, td {
  text-align: left;
  padding: 8px;
  border-bottom: 1px solid #ddd;
}
th {
  background-color: #f2f2f2;
}
a {
  color: #007bff;
  text-decoration: none;
}
table tr:hover {
  
  background: #999;
}

.contact-section {
  background-color: #fff;
  max-width: 1300px;
  padding: 20px;
  margin: 20px auto;
  /* border: 1px solid #ddd; */
}
.contact-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
}
.contact-details {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}
.contact-details div {
  width: 45%;
}
.contact-details address {
  font-style: normal;
  line-height: 1.6;
}




/* Responsive Design */
@media (max-width: 768px) {
  .hero-section {
    padding: 24px;
  }

  .hero-title {
    font-size: 18px;
  }

  .hero-subtitle-container h2 {
    font-size: 28px;
  }

  .global-maritime .container,
  .sustainability .container,
  .news-grid {
    flex-direction: column;
    grid-template-columns: 1fr;
  }

  .global-maritime .content,
  .sustainability .image-container {
    padding-right: 0;
    margin-bottom: 40px;
  }

  .sustainability .icons {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  .about-1-wrapper{
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 40px;
  }
}

