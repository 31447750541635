.contact-container-1 {

  width: 100%;
  max-width: 100%;
  overflow: hidden;
}

.hero-section-2 {
  position: relative;
  height: 100vh;
  background-image: url('../../../public/assets/pic-10.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  padding: 2rem;
  margin-bottom: 140px;
}

.hero-overlay-3 {
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 4rem;
  position: absolute;
  top: 50%;
}

.hero-overlay-3 h1 {
  font-size: 2.0rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-overlay-3 h2 {
  font-size: 1.8rem;
  font-weight: normal;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.emergency-contact-box-4 {
  background-color: #0077BE;
  top: 90%;
  left: 10%;
  width: 60%;
  height: 20%;
  position: absolute;
  padding: 2rem;
  margin-top: 2rem;
  border-radius: 5px;
  color: white;
}

.emergency-contact-content-5 {
  position: absolute;
  max-width: 1200px;
  margin: 0 auto;

}

.emergency-contact-content-5 p {
  margin: 0.5rem 0;
}


.social-links {
  position: absolute;
  display: flex;
  flex-direction: column;
  right: 4rem;
  top: 42rem;
  text-align: right;
  justify-content: center;
  color: #000;
}

.social-icons {
  display: flex;
  gap: 1rem;
  margin-top: -1.5rem;
  color: #000;
}

.social-icons a {
  color: #000;
  font-size: 2.2rem;
}

.social-links h3 {
  font-size: 1.9rem;
}
.contact-content {
  max-width: 1200px;
 
  margin: 0 auto;
  padding: 4rem 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4rem;
}

.form-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.form-section h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #0077BE;
}

.form-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.required {
  color: red;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.submit-button {
  background-color: #0077BE;
  color: white;
  padding: 1rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.submit-button:hover {
  background-color: #005c91;
}


.locations-section {
  display: grid;
  gap: 2rem;
}

.location-card {
  padding: 1.5rem;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.location-card h3 {
  color: #0077BE;
  margin-bottom: 1rem;
}

.location-card p {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.location-card a {
  color: #0077BE;
  text-decoration: none;
}

.location-card a:hover {
  text-decoration: underline;
}

.icon {
  color: #0077BE;
}


.map-section {

  background-color: #f5f5f5;
}

.map-section h2 {
  max-width: 1200px;
  margin: 0 auto 2rem;
  font-size: 2rem;
  position: relative;
}

.map-section h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #0077BE;
}

.map-container {
  max-width: 1200px;
  margin: 0 auto;
  height: 600px;
  border-radius: 4px;
  overflow: hidden;
}

@media (max-width: 1024px) {
  .contact-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
  .social-links{
    display: none;
  }
}

@media (max-width: 768px) {
  .hero-overlay-2 h1 {
    font-size: 2rem;
  }

  .hero-overlay-2 h2 {
    font-size: 1.5rem;
  }

  .emergency-contact-box-4{
    top: 100%;
    left: 0%;
    width: 90%;
    height: 20%; 
    margin-bottom: 2rem;
  }
  .social-links{
    display: none;
  }
  .form-row {
    grid-template-columns: 1fr;
  }

  /* .social-links {
    position: static;
    text-align: center;
    margin-top: 2rem;
  } */

  
}

@media (max-width: 480px) {
  .hero-overlay h1 {
    font-size: 1.5rem;
  }

  .hero-overlay h2 {
    font-size: 1.2rem;
  }

  .contact-content {
    padding: 2rem 1rem;
  }
}




@media (max-width: 1024px) {
  .contact-content {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}

/* @media (max-width: 768px) {
  .hero-overlay h1 {
    font-size: 2rem;
  }

  .hero-overlay h2 {
    font-size: 1.5rem;
  }

  .form-row {
    grid-template-columns: 1fr;
  }

  .social-links {
    position: static;
    text-align: center;
    margin-top: 2rem;
  }

  .social-icons {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  .hero-overlay h1 {
    font-size: 1.5rem;
  }

  .hero-overlay h2 {
    font-size: 1.2rem;
  }

  .contact-content {
    padding: 2rem 1rem;
  }
} */

    /* width: 100%;
    max-width: 100%;
    overflow: hidden;
  }
   */
  .hero-section-2 {
    position: relative;
    height: 100vh;
    background-image: url('../../../public/assets/pic-12.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    padding: 2rem;
    margin-bottom: 140px;
  }
  
  .hero-overlay-3 {
    max-width: 1200px;
    margin: 0 auto;
    padding-top: 4rem;
    position: absolute;
    top: 50%;
  }
  
  .hero-overlay-3 h1 {
    font-size: 2.0rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .hero-overlay-3 h2 {
    font-size: 1.8rem;
    font-weight: normal;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  
  .emergency-contact-box-4 {
    background-color: #0077BE;
    top: 90%;
    left: 10%;
    width: 60%;
    height: 20%;
    position: absolute;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 5px;
    color: white;
  }
  
  .emergency-contact-content-5 {
    position: absolute;
    max-width: 1200px;
    margin: 0 auto;
  
  }
  
  .emergency-contact-content-5 p {
    margin: 0.5rem 0;
  }
  
  
  .social-links {
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 4rem;
    top: 42rem;
    text-align: right;
    justify-content: center;
    color: #000;
  }
  
  .social-icons {
    display: flex;
    gap: 1rem;
    margin-top: -1.5rem;
    color: #000;
  }
  
  .social-icons a {
    color: #000;
    font-size: 2.2rem;
  }
  
  .social-links h3 {
    font-size: 1.9rem;
  }
  .contact-content {
    max-width: 1200px;
   
    margin: 0 auto;
    padding: 4rem 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
  
  .form-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .form-section h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: #0077BE;
  }
  
  .form-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
  }
  
  .form-group {
    margin-bottom: 1.5rem;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
  
  .required {
    color: red;
  }
  
  .form-group input,
  .form-group select,
  .form-group textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .submit-button {
    background-color: #0077BE;
    color: white;
    padding: 1rem 2rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #005c91;
  }
  
  
  .locations-section {
    display: grid;
    gap: 2rem;
  }
  
  .location-card {
    padding: 1.5rem;
    background-color: #f5f5f5;
    border-radius: 4px;
  }
  
  .location-card h3 {
    color: #0077BE;
    margin-bottom: 1rem;
  }
  
  .location-card p {
    margin: 0.5rem 0;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .location-card a {
    color: #0077BE;
    text-decoration: none;
  }
  
  .location-card a:hover {
    text-decoration: underline;
  }
  
  .icon {
    color: #0077BE;
  }
  
  
  .map-section {
  
    background-color: #f5f5f5;
  }
  
  .map-section h2 {
    max-width: 1200px;
    margin: 0 auto 2rem;
    font-size: 2rem;
    position: relative;
  }
  
  .map-section h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: #0077BE;
  }
  
  .map-container {
    max-width: 1200px;
    margin: 0 auto;
    height: 600px;
    border-radius: 4px;
    overflow: hidden;
  }
  
  @media (max-width: 1024px) {
    .contact-content {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
    .social-links{
      display: none;
    }
  }
  
  @media (max-width: 768px) {
    .hero-overlay-2 h1 {
      font-size: 2rem;
    }
  
    .hero-overlay-2 h2 {
      font-size: 1.5rem;
    }
  
    .emergency-contact-box-4{
      top: 100%;
      left: 0%;
      width: 90%;
      height: 20%; 
      margin-bottom: 2rem;
    }
    .social-links{
      display: none;
    }
    .form-row {
      grid-template-columns: 1fr;
    }
  
    /* .social-links {
      position: static;
      text-align: center;
      margin-top: 2rem;
    } */
  
    
  }
  
  @media (max-width: 480px) {
    .hero-overlay h1 {
      font-size: 1.5rem;
    }
  
    .hero-overlay h2 {
      font-size: 1.2rem;
    }
  
    .contact-content {
      padding: 2rem 1rem;
    }
  }
  
  
  
  
  @media (max-width: 1024px) {
    .contact-content {
      grid-template-columns: 1fr;
      gap: 2rem;
    }
  }
  
  /* @media (max-width: 768px) {
    .hero-overlay h1 {
      font-size: 2rem;
    }
  
    .hero-overlay h2 {
      font-size: 1.5rem;
    }
  
    .form-row {
      grid-template-columns: 1fr;
    }
  
    .social-links {
      position: static;
      text-align: center;
      margin-top: 2rem;
    }
  
    .social-icons {
      justify-content: center;
    }
  }
  
  @media (max-width: 480px) {
    .hero-overlay h1 {
      font-size: 1.5rem;
    }
  
    .hero-overlay h2 {
      font-size: 1.2rem;
    }
  
    .contact-content {
      padding: 2rem 1rem;
    }
  } */