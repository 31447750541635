
  
   
   .innovation-container {
    width: 100%;
    overflow: hidden;
    position: relative;
   
  }
  .innovation-hero-section {
    background-color: #f5f5f5;
    background-image: url("../../../public/assets/pic-8.jpg");
   position: relative;
    background-size: cover;
  background-position: center;
    height: 100vh;
  
    margin-bottom: 320px;
  }

  
  .innovation-hero-content {
    width: 60%;

    margin: 0 auto;
    /* padding-top: 4rem; */
    position: absolute;
   z-index: 2;
    top: 70%;
    left: 10%;
  }
  
  .innovation-hero-content h1 {
    color: #ffffff;
    z-index: 50;
    font-size: 2.0rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }
  


  .blur{
    background-color: rgba(0, 0, 0, 0.4);
    height: 100vh;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
    position: absolute;
    color: #fff;
    top: 90%;
    z-index: 50;
  }
  .section-gray h2{
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    position: absolute;
    top: 80%;
    color: #fff;
    z-index: 50;
    width: 50%;
  }

  
  
  
  .innovation-content-wrapper-4 {
    background-color: #0077BE;
    top: 90%;
    left: 8%;
    width: 60%;
    height: 35%;
    position: absolute;
    padding: 2rem;
    margin-top: 2rem;
    border-radius: 5px;
    color: white;
    z-index: 2;
    margin-bottom: 44rem;
  }
  
  .innovation-content-5 {
    position: absolute;
    max-width: 1200px;
    margin: 0 auto;
   
  
  }
  
  .innovation-content-5 p {
    margin: 0.5rem 0;
  }
  
  
  .innovative-contact {
  
    position: absolute;
    display: flex;
    width: 20%;
    flex-direction: column;
    right: 4rem;
    top: 40rem;
    text-align: right;
    justify-content: center;
    color: #000;
  }
  
  .innovative-icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: -1.5rem;
    color: #000;
  }
  
  .innovative-icons a {
    color: #000;
    font-size: 1.5rem;
    font-weight: 300;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    color: blue;
    padding: 10px 30px;
    border: 1px solid blue;
  }
  .innovative-icons a:hover{
    background-color: #0077BE;
    color: #fff;
    border-radius: 1px solid #fff;
  }
  .innovative-contact h3{
    color: black;
    font-weight: bolder;
    font-size: 1.2rem;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 100%;
    padding-bottom: 20px;
  }
  .service-indicators {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 2rem;
    margin-top: 15%;
    display: flex;
    gap: 1rem;
  }
  
  .indicator {
    width: 300px;
    height: 8px;
  }
  
  .indicator.blue {
    background-color: #00a7e1;
  }
  .indicator.green {
    background-color: #7ab800;
  }
  .indicator.black {
    background-color: #000000;
  }
  
  .background-attachment{
    background: url("../../../public/assets/pic-9.jpg");
  background-size: cover;
  background-position: center center;
  height: 80vh;
  background-attachment: fixed;
  margin-bottom: 2%;
  }
  
  .automooring-section{

    height: 100%;
  max-width: 1000px;
  margin: 0 auto;
  
  }
  .automooring-content-wrapper{
    z-index: 1;
  
  }

  .automooring-content-wrapper h2 {
    font-size: 1.5rem;
    margin-bottom: 4rem;
    position: relative;
  }
  
  .automooring-content-wrapper h2::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: #000000;
  }


  .automooring-grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    max-height: fit-content;
    margin-bottom: 3rem;
    margin: 20px auto;
    background-color: #99999957;
    padding: 0px ;
  }
    
  .ship-image {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;

  }
  .automooring-text-content{
    padding: 20px 20px 0px 20px;
    margin-bottom: 0px;
    
  }
  .improvements-section{
    padding: 4rem 2rem;
    max-width: 1200px;
    margin: 0 auto;
  }
  .improvements-section h3 {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    position: relative;
  }
  .improvements-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    margin-top: 2rem
  }
  .improvements-card{
    padding: 1.5rem;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .improvements-card h1 {
    font-size: 6rem;
    color: #00A7E1;
    margin-bottom: 1rem;
  }

  .improvements-card p{
    margin-bottom: 1rem;
  }
  .improvements-card h4{
    font-size: 11.5rem;
    margin-bottom: 1rem;

  }

  .mass-meter-cover {
   display: flex;
    gap: 2rem;
    margin-top: 2rem;
   padding-bottom: 0px;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    max-width: 1200px;
    padding: 3rem 2rem;
    background-color:#f5f5f5 ;
    padding-bottom: 0;
    margin-bottom: 0%;
  }
  
.mass-meter-content h2{
    font-size: 2rem;
    margin-bottom: 3rem;
    font-weight: bold;
    position: relative;
    color: black;

  }
  
  .mass-meter-content h2::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 4px;
    background-color: #000000;
  }
  .mass-content-wrapper p{
    margin-bottom: 1.2rem;
    line-height: 1.6;
    color:#999;
  }


  .mass-flow-video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    margin-top: 2rem;
  }
  
  .mass-flow-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 60%;
    height: 70%;
    border: 0;
  }

  .innovation-partnership-section {
    background-size: cover;
    background-position: center;
    color: #000;
    position: relative;
    background-color: #9999991a;
    width: 80%;
    margin: 0 auto;
  }
  
  .innovation-partnership-content {
    padding: 4rem 0;
    text-align: center;
    color: #000;

    
  }
  .innovation-partnership-content h2{
    font-size: 2rem;
    margin-bottom: 0;
    font-weight: bold;
    position: relative;
    color: #000;
    
  }
  
  .innovative-info-box {
  
    margin: 0 auto;
    width: 50%;
    padding: 2rem 3rem;
    color: white;
  }

  .innovative-info-content-wrapper{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
    color: #000;

  }


  
.telemetry-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.telemetry-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.telemetry-section h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #00a7e1;
}

.telemetry-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 10px 0;
}

ul li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.blue {
  background-color: #007bff;
  color: white;
}

.light-blue {
  background-color: #5bc0de;
  color: white;
}

.beige {
  background-color: #f5deb3;
}

.gray {
  background-color: #dcdcdc;
}

.black {
  background-color: black;
  color: white;
}

.innovative-sustainability-section{
  background-image: url(../../../public/assets/pic-4.jpg);
  background-size: cover;
  background-position: center center;
  height: 100vh;
  background-attachment: fixed;
  position: relative;
}



.sustainability-grid{
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 0rem;
  background-color: rgba(128, 128, 128, 0.397);
  margin: 0 auto;
  position: absolute;
  top: 10%;
  left: 20%;
  width: 50%;
  margin-top: 0.5rem

}
.sustainability-text-content h2{
  font-size: 1.2rem;
  margin-bottom: 3rem;
  font-weight: bold;
  position: relative;
  color: black;

}

.sustainability-text-content h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #000000;
}
.sustainability-text-content p{
  font-size: 14px;
  line-height: 1.2;
  padding-bottom: 0;
  margin-bottom: 0;
  padding: 2rem;
  
}
.sustainability-value-items{
  /* display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem; */
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-bottom: 0;
  margin-bottom: 0;
}

.sustainability-image-container{
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  padding: 2rem;
}

.globe-image{
  max-width: 70%;
  height: 70%;
  object-fit: cover;
  border-radius: 4px;
}

  
  @media (max-width: 1024px) {
    .automooring-grid,
    .sustainability-grid {
      grid-template-columns: 1fr;
    }
  
    .improvements-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 768px) {
    .innovation-hero-section {
      height: 100vh;
    }
    .innovation-hero-content {
      width: 80%;
      left: 10%;
      top: 60%;
      margin-bottom: 3rem;

    }
    .innovation-hero-content h1 {
      font-size: 1.75rem;
      padding-bottom: 3rem;
    }

    .background{
      background-size: 100%;
      margin-bottom: 30%;
      margin-top: 30%;
    }
    
    .innovative-contact{
      display: none;
    }
    .sustainability-grid{
      display: grid;
      grid-template-columns: repeat(1, 2fr);
      gap: 0rem;
      background-color: rgba(128, 128, 128, 0.397);
      margin: 0 auto;
      position: absolute;
      top: 0%;
      left: 5%;
      margin-left: 10px;
      width: 80%;
      height: 100%;
      
    }

    .globe-image{
      max-width: 50%;
      height: 40%;
      object-fit: cover;
      border-radius: 4px;
    }

    .innovative-sustainability-section{
  
      height: 140vh;
      background-attachment: fixed;
      position: relative;
    }
    .innovation-content-wrapper-4{
      background-color: #0077BE;
      top: 100%;
      left: 1%;
      width: 80%;
      height: 80%;
      position: absolute;
      padding: 2rem;
      margin-top: 2rem;
      border-radius: 5px;
      color: white;
      z-index: 2;
      margin-bottom: 100%;
    }
  
    .sustainability-text-content h2{
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      font-weight: bold;
      position: relative;
      color: black;
    
    }
    .improvements-grid,
    .values-grid {
      grid-template-columns: 1fr;
    }
  
    .content-wrapper {
      padding: 0 1rem;
    }
  }
  




 






































  /* .content-wrapper {
    position: absolute;
    top: 50%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 2rem;
  }
  .content-wrapper h2 {
    font-size: 1.5rem;
    
    font-weight: bold;
  }
  
  .content-wrapperh3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  
  .content-wrapperp {
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  
  .mass-flow-intro {
    padding: 3rem 0;
  }
   */