.services-container {
  width: 100%;
  overflow: hidden;
}

.hero-section-service {
  position: relative;
  height: 100vh;

  background-image: url("../../../public/assets/pic-5.jpg");

  background-size: cover;
  background-position: center;
  margin-bottom: 0;
}

.hero-content-service {
  position: absolute;
  top: 55%;
  max-width: 1200px;
  width: 60%;
  margin: 0 auto;
  padding: 2rem;
  margin-left: 7rem;
  font-size: 20px;
  z-index: 1;
}

.hero-content-service h1 {
  position: absolute;
  top: 80%;
  color: white;
  font-size: 2rem;
  margin-top: 50px;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.info-box-service {
  position: absolute;
  bottom: -21%;
  left: 10%;
  font-size: 1.2rem;
  background-color: #00a7e1;
  color: white;
  padding: 1.5rem;
  line-height: 1.5;
  width: 60%;
  margin: 0 auto;
}

.info-box-service .main-description-service,
.info-box-service .secondary-description-service {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
}

.service-indicators {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 0 2rem;
  margin-top: 15%;
  display: flex;
  gap: 1rem;
}

.indicator {
  width: 300px;
  height: 8px;
}

.indicator.blue {
  background-color: #00a7e1;
}
.indicator.green {
  background-color: #7ab800;
}
.indicator.black {
  background-color: #000000;
}

.bunkering-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.bunkering-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.bunkering-section h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #00a7e1;
}

.bunkering-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.transportation-section {
  /* background: url(../../../public/assets/pexels-julien-goettelmann-44396125-28966482.jpg); */
  background: url("../../../public/assets/pic-6.jpg");

  background-size: cover;
  background-position: center center;
  height: 70vh;
  background-attachment: fixed;
  margin-bottom: 20%;
}

.transportation-section .overlay {
  position: absolute;
  width: 60%;
  top: 150;
  left: 70;
  margin-left: 30%;
  margin-top: 30%;
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 4rem 2rem;
}

.transportation-section h2 {
  font-size: 2rem;
  margin-bottom: 2rem;
  position: relative;
}

.transportation-section h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: white;
}

.transportation-section h3 {
  font-size: 1.5rem;
  max-width: 800px;
  margin-bottom: 1rem;
}

.fleet-section {
  padding: 4rem 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.fleet-section h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  position: relative;
}

.fleet-section h2::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 4px;
  background-color: #00a7e1;
}

.fleet-section h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
ul {
  list-style-type: none;
  padding: 0;
}
ul li {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin: 10px 0;
}

ul li span {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  /* font-size: 16px;
  margin: 10px 0;
  padding: 10px;
  border-radius: 5px; */
}

.blue {
  background-color: #007bff;
  color: white;
}

.light-blue {
  background-color: #5bc0de;
  color: white;
}

.beige {
  background-color: #f5deb3;
}

.gray {
  background-color: #dcdcdc;
}

.black {
  background-color: black;
  color: white;
}
.hightlight {
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
}





@media (max-width: 768px) {
  .hero-content-service{
    margin-left: 0;
    width: 100%;
    top: 45%;
    text-align: center;
    padding-left: 0;
    margin-left: 0;

  }

  /* .hero-content-service {
    position: absolute;
    top: 55%;
    max-width: 1200px;
    width: 60%;
    margin: 0 auto;
    padding: 2rem;
    margin-left: 7rem;
    font-size: 20px;
    z-index: 1;
  } */
  .service-indicators,.indicator .blue, .indicator .green, .indicator .black {
    display: none;
    display: hidden;
  }
  .hero-content-service h1 {
    font-size: 30px;
    width: 70%;
    align-items: center;
    justify-content: center;
    text-align: justify;
    padding-left: 10px;
    
  }

  .info-box-service {
    
    padding: 1.5rem;
    width: 80%;
    height: 40%;
    left: 10%;
    margin-top: 10px;
    margin-left: 10px;
    line-height: 1.3;
    top: 100%;
    font-size: 1.0rem;
    

    top: 100;
    left: 0%;
    font-size: 1.2rem;
  
    padding: 1.5rem;
  
    

  





  }
  .info-box-service .secondary-description-service{
    padding-bottom: 100rem;
  }
  .bunkering-section{
    margin-top: 10%;
    padding-top: 20%;
  }
  .bunkering-section h2 {
    font-size: 1.75rem;
    margin-top: 100px;
  }

  
  .main-description-service {
    font-size: 1.1rem;
  }

  .service-indicators {
    flex-wrap: wrap;
  }

  .indicator {
    width: 100px;
  }
  .transportation-section .overlay {
    position: absolute;
    width: 80%;
    margin: 1rem;
    top: 200;
    left: 10;
    margin-left: 2%;
    margin-top: 10%;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 4rem 2rem;
  }
  
  .transportation-section h2 {
    font-size: 1.75rem;
    margin-bottom: 1.5rem;
    position: relative;
  }
}

@media (max-width: 480px) {
  .hero-section-service h1 {
    font-size: 2rem;
  }

  .transportation-section h2,
  .bunkering-section h2,
  .fleet-section h2 {
    font-size: 1.75rem;
  }

  .transportation-section h3,
  .bunkering-section h3,
  .fleet-section h3 {
    font-size: 1.25rem;
  
  }
  .service-indicators,.indicator .blue, .indicator .green, .indicator .black {
    display: none;
    display: hidden;
  }

} 
@media (max-width: 1024px) {
  .hero-content-service {
    margin-left: 0;
    width: 100%;
    text-align: center;
    padding-left: 0;
    margin-left: 0;
  }
  .service-indicators,.indicator .blue, .indicator .green, .indicator .black {
    display: none;
    display: hidden;
  }
  
}
